import React from 'react';
import { ChevronUp, ChevronDown } from "lucide-react";
var SmallCardWithChart = function (_a) {
    var title = _a.title, value = _a.value, data = _a.data, percentage = _a.percentage;
    return (<div className="w-full h-full p-6 bg-white">
            <div className="flex items-center justify-between mb-2.5">
                <div className="text-[15px] text-gray-900 font-light">
                    {title}
                </div>
                {/* <div>
            {percentage && (
                <div className={`text-xs font-medium flex items-center gap-x-0.5 px-1.5 py-0.5 rounded-full ${percentage >= 0 ? 'text-green-950 bg-green-100' : 'text-red-950 bg-red-100'}`}>
                {percentage >= 0 ? <ChevronUp className="size-4" /> : <ChevronDown className="size-4" />}
                    {percentage}%
                </div>
            )}
        </div> */}
            </div>

            <div className="flex items-center justify-between gap-0.5">
                <div className="text-3xl font-medium w-full">
                    <div className="flex items-center justify-between gap-x-0.5">
                        <div className="text-3xl font-medium">{value}</div>
                        {percentage && (<div className={"text-base font-medium flex items-center gap-x-0.5 px-1.5 py-0.5 ".concat(percentage >= 0 ? 'text-green-600' : 'text-rose-600')}>
                            {percentage >= 0 ? <ChevronUp className="size-4"/> : <ChevronDown className="size-4"/>}
                                {percentage}%
                            </div>)}
                    </div>
                </div>
                {/* <div className="w-1/3">
            <Chart2 data={data} />
        </div> */}
            </div>
        </div>);
};
export default SmallCardWithChart;
