import React from 'react';
import { Component } from '@/components/chart';
import { TopBarChart } from '@/components/bar';
import { TopBarChart2 } from '@/components/bar2';
import { MyPieChart } from '@/components/pie';
import { Chart2 } from '@/components/chart2';
import SmallCardWithChart from '@/components/smallCardWithChart';
import { ProposalsAreaChart } from '@/components/proposalsAreaChart';
import { TeamSelect } from '@/components/teamSelect';

import { LineChart, Line } from 'recharts';
const data = [{name: 'Page A', uv: 400, pv: 2400, amt: 2400}];

export default function (props) {
    const organization = JSON.parse(props.organization);

    return <>
        <div className="flex flex-col gap-y-10">
            <div>
                <div className="grid grid-cols-5 gap-6">
                    <div className="col-span-3 bg-white rounded-xl p-4 border border-gray-200/70">
                        <div className="flex items-center pt-1 pb-8 pl-2">
                            <div>
                                <div className="text-gray-500 text-sm">Sent</div>
                                <div className="text-xl font-semibold">
                                    {props.stats.sent || 0}
                                </div>
                            </div>
                            <div className="h-12 border-l border-gray-200 mx-8"></div>
                            <div>
                                <div className="text-gray-500 text-sm">Viewed</div>
                                <div className="text-xl font-semibold">
                                    {props.stats.viewed || 0}
                                </div>
                            </div>
                            <div className="h-12 border-l border-gray-200 mx-8"></div>
                            <div>
                                <div className="text-gray-500 text-sm">Replies</div>
                                <div className="text-xl font-semibold">
                                    {props.stats.interviewed || 0}
                                </div>
                            </div>
                            <div className="h-12 border-l border-gray-200 mx-8"></div>
                            <div>
                                <div className="text-gray-500 text-sm">Hires</div>
                                <div className="text-xl font-semibold">
                                    {props.stats.hired || 0}
                                </div>
                            </div>
                        </div>
                        <div className="h-[250px]">
                            <ProposalsAreaChart organization={organization} stats={props.stats} />
                        </div>
                    </div>
                    <div className="col-span-2 flex flex-col">
                        <div className="flex items-center justify-between mb-6 rounded-xl border border-transparent bg-white p-4 bg-gradient-to-r from-green-500/30 via-emerald-500/30 to-teal-500/30">
                            <div className="w-1/2">
                                <div className="text-base text-gray-900 font-light">
                                    {organization.type === 'agency' ? 'Team balance' : 'Available balance'}
                                </div>
                                <div className="text-xl font-medium">
                                    {organization.connectsBalance ? organization.connectsBalance.toLocaleString() : 'N/A'} connects
                                </div>
                            </div>
                            
                            <div className="h-10 border-l border-gray-900 mx-4"></div>
                            
                            <div className="w-1/2">
                                <div className="text-base text-gray-900 font-light">
                                    Earnings
                                </div>
                                <div className="text-xl font-medium">
                                    {organization.earnings ? `$${organization.earnings.toLocaleString()}` : 'N/A'}
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-2 h-full rounded-xl overflow-hidden">
                            <div className="col-span-1 h-full border border-gray-200/70 rounded-tl-xl overflow-hidden">
                                <SmallCardWithChart title="Connects spent" value={props.stats.connectsSpent.toLocaleString()} data={props.stats.connectsSpentByDate} percentage={props.stats.connectsSpentChange} />
                            </div>
                            <div className="col-span-1 h-full border-t border-r border-b border-gray-200/70 rounded-tr-xl overflow-hidden">
                                <SmallCardWithChart title="View rate" value={`${props.stats.viewRate}%`} data={props.stats.viewRateByDate} percentage={props.stats.viewRateChange} />
                            </div>
                            <div className="col-span-1 h-full flex items-center justify-center border-x border-b border-gray-200/70 rounded-bl-xl overflow-hidden">
                                <SmallCardWithChart title="Reply rate" value={`${props.stats.replyRate}%`} data={props.stats.replyRateByDate} percentage={props.stats.replyRateChange} />
                            </div>
                            <div className="col-span-1 h-full flex items-center justify-center border-r border-b border-gray-200/70 rounded-br-xl overflow-hidden">
                                <SmallCardWithChart title="Connects per reply" value={props.stats.connectsPerReply.toLocaleString()} data={props.stats.connectsPerReplyByDate} percentage={props.stats.connectsPerReplyChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}
