"use client";
import React from "react";
import { Area, AreaChart, CartesianGrid, XAxis } from "recharts";
import { ChartContainer, ChartTooltip, ChartTooltipContent, } from "@/components/ui/chart";
var chartConfig = {
    sent: {
        label: "Sent",
        color: "rgb(37, 99, 235)",
        index: 0,
    },
    viewed: {
        label: "Viewed",
        color: "rgb(96, 168, 251)",
        index: 1,
    },
    interviewed: {
        label: "Interviewed",
        color: "rgb(91, 33, 182)",
        index: 2,
    },
    hired: {
        label: "Hired",
        color: "rgb(22, 163, 74)",
        index: 3,
    },
};
export function ProposalsAreaChart(props) {
    var chartData = Object.entries(props.stats.proposalsByDate).map(function (_a) {
        var date = _a[0], item = _a[1];
        return ({
            month: item.label,
            sent: item.sent,
            viewed: item.viewed,
            interviewed: item.interviewed,
            hired: item.hired,
        });
    });
    var lineType = "monotone";
    return (<>
            <ChartContainer config={chartConfig} className="w-full h-[250px]">
                <AreaChart width={500} height={250} accessibilityLayer data={chartData}>
                    <CartesianGrid vertical={false}/>
                    <XAxis dataKey="month" tickLine={false} axisLine={false} tickMargin={0}/>
                    <ChartTooltip cursor={true} content={<ChartTooltipContent indicator="dot"/>}/>
                    <defs>
                        <linearGradient id="fillHired" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="var(--color-hired)" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="var(--color-hired)" stopOpacity={0.1}/>
                        </linearGradient>
                        <linearGradient id="fillInterviewed" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="var(--color-interviewed)" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="var(--color-viewed)" stopOpacity={0.1}/>
                        </linearGradient>
                        <linearGradient id="fillViewed" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="var(--color-viewed)" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="var(--color-viewed)" stopOpacity={0.1}/>
                        </linearGradient>
                        <linearGradient id="fillSent" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="var(--color-sent)" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="var(--color-sent)" stopOpacity={0.1}/>
                        </linearGradient>
                    </defs>
                    <Area dataKey="hired" type={lineType} fill="url(#fillHired)" fillOpacity={0.4} stroke="var(--color-hired)" stackId="a"/>
                    <Area dataKey="interviewed" type={lineType} fill="url(#fillInterviewed)" fillOpacity={0.4} stroke="var(--color-interviewed)" stackId="a"/>
                    <Area dataKey="viewed" type={lineType} fill="url(#fillViewed)" fillOpacity={0.4} stroke="var(--color-viewed)" stackId="a"/>
                    <Area dataKey="sent" type={lineType} fill="url(#fillSent)" fillOpacity={0.4} stroke="var(--color-sent)" stackId="a"/>
                </AreaChart>
            </ChartContainer>
        </>);
}
