import { Controller } from "@hotwired/stimulus";
import { getComponent } from "@symfony/ux-live-component";

import React from "react";
import { createRoot } from "react-dom/client";
import Dashboard from "../react/controllers/Dashboard";

import "preline/index";

export default class extends Controller {
    async initialize() {
        this.component = await getComponent(this.element);

        const rootElement = this.element.querySelector("#dashboard-react");
        const props = JSON.parse(rootElement.getAttribute("data-props"));
        const root = createRoot(rootElement);
        root.render(<Dashboard {...props} />);

        const tooltips = this.element.querySelectorAll(".init-hs-tooltip");
        tooltips.forEach((tooltip) => {
            tooltip.classList.remove("init-hs-tooltip");
            tooltip.classList.add("hs-tooltip");
            HSTooltip.autoInit(tooltip);
        });

        this.component.on("render:finished", (component) => {
            let trs = this.element.querySelectorAll(".js-proposal-details-toggle");
            trs.forEach((tr) => {
                tr.classList.remove("open");
                tr.nextElementSibling.classList.remove("open");
            });

            const props = JSON.parse(
                this.element
                    .querySelector("#dashboard-react")
                    .getAttribute("data-props")
            );
            root.render(<Dashboard {...props} />);

            const tooltips = this.element.querySelectorAll(".init-hs-tooltip");
            tooltips.forEach((tooltip) => {
                tooltip.classList.remove("init-hs-tooltip");
                tooltip.classList.add("hs-tooltip");
                HSTooltip.autoInit(tooltip);
            });
        });
    }
}
