import { Controller } from '@hotwired/stimulus';
import { getComponent } from '@symfony/ux-live-component';

import 'preline/index';

export default class extends Controller {
    async initialize() {
        this.component = await getComponent(this.element);

        this.component.on('render:finished', (component) => {
            const selects = this.element.querySelectorAll('.hs-select');
            selects.forEach(select => {
                select.remove();
            });

            HSSelect.autoInit();
        });
    }

    connect() {
        this.handlePageUpdate = this.handlePageUpdate.bind(this);
        window.addEventListener('updatePage', this.handlePageUpdate);
    }

    disconnect() {
        window.removeEventListener('updatePage', this.handlePageUpdate);
    }

    handlePageUpdate(event) {
        const url = event.detail.url;
        window.history.pushState({}, '', url);

        document.title = event.detail.metaTitle;
        document.querySelector('meta[name="description"]').setAttribute('content', event.detail.metaDescription);
        document.querySelector('h1').textContent = event.detail.h1;
        document.querySelector('p').textContent = event.detail.description;
    }
}
